<template>
  <section>
    <v-row>
      <v-col>
        <v-breadcrumbs :items="breadcrumbs" class="ma-0 pa-0" />
      </v-col>
    </v-row>

    <v-row>
      <v-col class="d-flex justify-space-between flex-wrap">
        <h1>{{ customer.full_name | uppercase }}</h1>

        <span>
          <v-btn class="mr-3" color="warning" :to="{ name: 'customer-form', params: { uuid } }">
            <v-icon left>mdi-pencil</v-icon>
            Editar
          </v-btn>

          <v-btn color="error" @click="handleDeleteModal">
            <v-icon left>mdi-delete</v-icon>
            Eliminar
          </v-btn>
        </span>
      </v-col>
    </v-row>

    <v-card>
      <v-container>
        <v-row>
          <v-col cols="12" sm="6"> <b>Nombre:</b> {{ customer.first_name }} </v-col>

          <v-col cols="12" sm="6"> <b>Apellido Paterno:</b> {{ customer.last_name }} </v-col>

          <v-col cols="12" sm="6"> <b>Apellido Materno:</b> {{ customer.mother_last_name }} </v-col>

          <v-col cols="12" sm="6"> <b>RFC:</b> {{ customer.rfc }} </v-col>

          <v-col cols="12" sm="6"> <b>Correo electrónico:</b> {{ customer.email }} </v-col>

          <v-col cols="12" sm="6">
            <b>Teléfono de casa:</b> {{ customer.home_phone || '---' }}
          </v-col>

          <v-col cols="12" sm="6"> <b>Celular:</b> {{ customer.mobile_phone }} </v-col>

          <v-col cols="12" sm="6">
            <b>Fecha de nacimiento:</b> {{ customer.birthday | date }}
          </v-col>

          <v-col cols="12" sm="6">
            <b>Observaciones:</b> {{ customer.observations || '---' }}
          </v-col>

          <v-col cols="12" sm="6">
            <b>Sucursal:</b> {{ (customer.office_object && customer.office_object.name) || '---' }}
          </v-col>

          <v-col v-if="isNotCustomer" cols="12" sm="6">
            <b>Rol:</b> {{ customer.user.role | role }}
          </v-col>
        </v-row>

        <v-divider class="my-5"></v-divider>

        <AddressDetail :address="customer.address" />
      </v-container>
    </v-card>

    <Modal :show.sync="showDeleteModal" @submit="remove">
      <template #title>Eliminar</template>

      <template #body>
        ¿Deseas eliminar al cliente
        <b>{{ customer.full_name | uppercase }}</b>
        ?
      </template>

      <template #action>Eliminar</template>
    </Modal>
  </section>
</template>

<script>
// Config
import config from '@/config';

// API
import CustomerAPI from '@/api/customer';

// Components
import AddressDetail from '@/components/address/Detail.vue';

// Mixins
import DetailMixin from '@/mixins/DetailMixin';

export default {
  name: 'CustomerDetail',

  props: {
    uuid: {
      type: String,
      required: true,
    },

    role: {
      type: String,
      default: null,
    },
  },

  components: {
    AddressDetail,
  },

  mixins: [DetailMixin],

  data: () => ({
    customer: {
      address: {},
      birthday: new Date(),
    },
    listRouteName: 'customer-list',
  }),

  computed: {
    breadcrumbs() {
      const name = this.customer.full_name || '';
      return [
        {
          text: 'Clientes',
          disabled: false,
          exact: true,
          to: { name: 'customer-list' },
        },
        {
          text: name.toUpperCase(),
          disabled: true,
        },
      ];
    },

    isNotCustomer() {
      return this.role !== config.roles.CUSTOMER.key;
    },
  },

  async created() {
    this.api = new CustomerAPI();
    await this.detail();
  },

  methods: {
    async detail() {
      this.$store.commit('showLoader');
      try {
        this.customer = await this.api.detail(this.uuid);
      } catch (error) {
        this.$store.dispatch('notification/notifyError');
      }
      this.$store.commit('hideLoader');
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
